import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlInputGroupModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';

const fieldTypeMap = {
    'NUMBER': 'NUMBER',
    'TEXT': 'TEXT',
    'PERSON': 'TEXT',
    'MOBILEPHONE': 'TEXT',
    'RICHTEXT': 'RICHTEXT',
    'TABLE': 'RICHTEXT',
    'DATE': 'DATE',
    'DROPDOWN': 'DROPDOWN',
    'DEPARTMENT': 'DROPDOWN',
    'PROFESSION': 'DROPDOWN',
    'SPACE': 'DROPDOWN',
    'DEVICE': 'DROPDOWN',
    'SPAREASSET': 'DROPDOWN',
    'LEVEL': 'DROPDOWN',
    'MULTISELECT': 'MULTISELECT',
    'IMAGE': 'IMAGE',
    'IMAGES': 'IMAGES',
    'ATTACHMENT': 'ATTACHMENT',
    'ATTACHMENTS': 'ATTACHMENTS'
};

const fieldTypeOptions = [
    {
        name: '数值输入框',
        value: 'NUMBER'
    },
    {
        name: '单行输入框',
        value: 'TEXT'
    },
    {
        name: '多行输入框',
        value: 'RICHTEXT'
    },
    {
        name: '日期时间选择器',
        value: 'DATE'
    },
    {
        name: '单选下拉列表',
        value: 'DROPDOWN'
    },
    {
        name: '多选下拉列表',
        value: 'MULTISELECT'
    },
    {
        name: '单张图片',
        value: 'IMAGE'
    },
    {
        name: '多张图片(不超过9张)',
        value: 'IMAGES'
    },
    {
        name: '单个文件',
        value: 'ATTACHMENT'
    },
    {
        name: '多个文件',
        value: 'ATTACHMENTS'
    }
];

export class WorkOrderFormDesignEntityModel extends BaseEntityModel {
    // 描述
    description: string = undefined;

    @FormControl({
        label: '字段名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    displayName: string = undefined;

    @FormControl({
        label: '字段标识符',
        type: FormControlType.TEXT,
        invisibleFunction: (model:WorkOrderFormDesignEntityModel) => { return model.system; },
        required: true,
        readonly: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '字段类型',
        type: FormControlType.SELECT,
        options: fieldTypeOptions,
        invisibleFunction: (model:WorkOrderFormDesignEntityModel) => { return model.system; },
        readonly: true,
        required: true
    } as FormControlOptionModel)
    dataType: string = undefined;

    @FormControl({
        label: '枚举项',
        type: FormControlType.INPUT_GROUP,
        options: [],
        invisible: true,
        invisibleFunction: (model:WorkOrderFormDesignEntityModel) => { return ((model.dataType !== 'DROPDOWN' && model.dataType !== 'MULTISELECT') || model.system); },
        required: false,
        readonly: true
    } as FormControlInputGroupModel)
    // 选项
    options: any = undefined;

    // @FormControl({
    //     label: 'api路由',
    //     type: FormControlType.TEXT,
    //     invisible: true,
    //     invisibleFunction: (model:WorkOrderFormDesignEntityModel) => { return (model.dataType !== 'DROPDOWN' && model.dataType !== 'TreeDROPDOWN') || model.sjy !== 'dataSource'; },
    //     required: true
    // } as FormControlTextModel)
    // 数据源(暂不使用)
    dataSource: string = undefined;

    // @FormControl({
    //     label: '默认值',
    //     type: FormControlType.TEXT
    // } as FormControlTextModel)
    // value: string = undefined;

    // 字段序号
    index: string = undefined;

    // 排版分组
    groupId: number = 0;

    // 表单Id
    formId: string = undefined;

    // 最大文件数
    maxSize: number = undefined;

    // 最大值 (暂时无用)
    maximum: number = undefined;
    // 最小值(暂时无用)
    mininum: number = undefined;
    // 是否系统内置字段
    system: boolean = false;
    // 是否必须字段（此处不使用）
    required: boolean = false;
    // 是否可见
    readonly: boolean = true;
    // 是否只读
    visible: boolean = true;

    static getTableColumns() {
        return [
            {
                title: '序号',
                scopedSlots: { customRender: 'index' },
                width: 60
            },
            {
                title: '字段名称',
                dataIndex: 'displayName',
                width: 300
            },
            {
                title: '字段标识符',
                dataIndex: 'name',
                width: 500
            },
            {
                title: '字段类型',
                dataIndex: 'dataType',
                customRender: (text, record, index) => {
                    return _.filter(fieldTypeOptions, item => { return fieldTypeMap[record.dataType] === item.value; })[0].name;
                }
            },
            {
                title: '系统字段',
                dataIndex: 'system',
                customRender: (text, record, index) => {
                    return record.system ? '是' : '否';
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (_.get(json, 'options')) {
            this.options = JSON.parse(_.get(json, 'options'));
        }

        return this;
    }

    toService() {
        const data: any = super.toService();
        if (data.dataType !== 'DROPDOWN' && data.dataType !== 'MULTISELECT') {
            delete data.options;
        } else if (data.options && data.options.length === 1 && data.options[0].name === '') {
            delete data.options;
        } else {
            data.options = JSON.stringify(data.options);
        }
        return data;
    }
}

export class WorkOrderFormDesignQueryModel extends QueryPageModel {
    keyWords: string = null;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
